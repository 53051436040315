const translations = {
  English: {
    sideBar: {
      Home: "Home",
      Explore: "Explore",
      Messages: "Messages",
      Bookmarks: "Bookmarks",
      Notifications: "Notifications",
      Subscription: "Subscription",
      Profile: "My Profile",
      Settings: "Settings",
      Calender: "Calendar",
      LogOut: "Log Out",
      LogIn: "Log In",
    },
    settingsOne: {
      aboutMe: "About Me",
      username: "Username",
      email: "Email",
      phone: "Phone",
      myPassword: "My Password",
      role: "Role",
      readMore: "...Read more",
    },
    settingsTwo: {
      country: "Country",
      state: "State",
      city: "City",
      displayLanguage: "Display Language",
      readMore: "...Read more",
    },
    settingsThree: {
      termsAndConditions: "Terms & Conditions",
      privacyPolicy: "Privacy Policy",
      cancellationPolicy: "Cancellation & Refund Policy",
      readMore: "...Read more",
      read: "Read",
    },

    headings: {
      settings: "Settings",
      notifications: "Notifications",
      myProfile: "My Profile",
      profile: "Profile",
      homepage: "Home",
      latestPosts: "Latest Posts",
      explore: "Explore",
      home: "Home",
      messages: "Messages",
      bookmarks: "Bookmarks",
      subscription: "Subscription",
      users: "Users",
      calender: "Calendar",
    },
    buttons: {
      publish: "Publish",
      subscribeNow: "Subscribe Now",
      showMore: "Show More",
      all: "All",
      scheduled: "Scheduled",
      pending: "Pending",
    },
    tags: {
      job_seeker_post: "Job Seeker Posts",
      job_seeker_profile: "Job Seeker Profiles",
      emp_profile: "Employer Profiles",
      emp_post: "Job Posts",
    },
    types: {
      jobseeker: "Job Seeker",
      employer: "Employer",
    },

    actions: {
      reportUser: "Report User",
      reportPost: "Report Post",
      repostPost: "Repost Post",
      bookmarkUser: "Bookmark User",
      editPost: "Edit Post",
      deletePost: "Delete Post",
      pinToTop: "Pin to the Top",
      UnPinToTop: "Unpin from the top",
      blockUser: "Block User",
      deleteChat: "Delete Chat",
      removeBookmark: "Remove Bookmark",
      Showless: "Show less",
    },
    bookmarkOptions: {
      chats: "Messages",
      posts: "Posts",
      profiles: "Profiles",
    },
    subscriptionTexts: {
      cancelSubscription: "Please Cancel my subscription",
      value: "Subscription Value",
      Duration: "Duration",
      Renews: "Renews on",
      Upgrade: "Upgrade to Premium Subscriber.",
      UpgradeText:
        "Adding Highlights to your posts will greatly increase your the interaction from other Boca En Boca users. This is a month-to-month subscription.",
      UpgradeButton: "Upgrade My Account",
      Downgrade: "Downgrade My Premium Subscription ",
      DowngradeText:
        "At the end of my annual Premium Subscription, I would like to downgrade my account to become a regular Subsciber. The regualr Subscription is completely ad-free. We appreciate your support.",
      DowngradeButton: "Downgrade To Regular Subscription",
      Premium: "You are a Premium Subscriber.",
      premiumText:
        "All of your posts are highlighted. This will greatly increase the interaction from other Boca En Boca users. This is a month-to-month subscription.",
      piad: "You are a Paid Subscriber.",

      paidTexts:
        "Thank you for subscribing to Boca En Boca. You are helping to connect Employers with Job Seekers, ad-free. We appreciate your support.",
      annual: "Annual Subscription",
      annualTexts:
        "With a free account, you'll encounter brief ads to assist Boca En Boca in connecting Employers with Job Seekers. Upgrade to our Annual Subscription plan to enjoy an ad-free experience throughout the app.",
      annualPrice: "$9.99/Year",
      subscribeAccess: "Subscribe Access",
      paywithcard: "Pay with card",
      thankYouTexts:
        "Thank you for your support in helping us to connect Employers with Job Seekers.",
      continue: "Continue",
    },
    profileExtras: {
      posts: "Posts",
      bookmarks: "Bookmarks",
      responseTime: "Response Time",
      about: "About Me",
    },
    logoutmodal: {
      text: "Are you sure you want to logout?",
      cancel: "Cancel",
      confirm: "  Confirm",
    },
    loginmodal: {
      text: " Please log in or create a free account to continue.",
      button: "  Take me to Login/Signup",
    },
    signUpPage: {
      signUp: "Sign Up",
      welcome: "Welcome to",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      username: "Username",
      telephone: "Telephone",
      password: "Password",
      confirmPassword: "Confirm Password",
      selectState: "Select State",
      selectCity: "Select City",
      jobSeeker: "Job-seeker",
      employer: "Employer",
      agreeTerms: "I Agree to Terms of Use And Privacy Policy",
      signUpButton: "Sign Up",
    },
    loginPage: {
      welcome: "Welcome to",
      welcomeBack: "Welcome Back",
      emailPhoneOrUsername: "Email, Phone Number or User Name",
      enterPassword: "Enter Password",
      rememberMe: "Remember me",
      forgotPassword: "Forgot Password",
      logIn: "Log In",
      orSignInWith: "Or Sign in with",
      noAccount: "Don’t have an account?",
      signUpForFree: "Sign Up for Free",
    },
    InterviewSchedule: {
      submit: "Send Proposed Interview",
      timeone: "Time Option One",
      timetwo: "Time Option Two ",
      interviewtype: "Interview Type",
    },
  },

  Spanish: {
    sideBar: {
      Home: "Inicio",
      Explore: "Explorar",
      Messages: "Mensajes",
      Bookmarks: "Guardados",
      Notifications: "Notificaciones",
      Subscription: "Suscripción",
      Profile: "Mi perfil",
      Calender: "Calendario",
      Settings: "Configuración",
      LogOut: "Cerrar sesión",
      LogIn: "Iniciar sesión",
    },
    settingsOne: {
      aboutMe: "Acerca de mí",
      username: "Nombre de usuario",
      email: "Correo electrónico",
      phone: "Teléfono",
      myPassword: "Mi contraseña",
      role: "Rol",
      readMore: "...Leer más",
    },
    settingsTwo: {
      country: "País",
      state: "Estado",
      city: "Ciudad",
      displayLanguage: "Idioma",
      readMore: "...Leer más",
    },
    settingsThree: {
      termsAndConditions: "Términos y condiciones",
      privacyPolicy: "Política de privacidad",
      cancellationPolicy: "Política de cancelación y reembolso",
      readMore: "...Leer más",
      read: "Leer",
    },
    headings: {
      settings: "Configuracións",
      notifications: "Notificaciones",
      myProfile: "Mi perfil",
      profile: "Perfil",
      homepage: "Página principal",
      latestPosts: "últimas publicaciones",
      explore: "Explorar",
      home: "Hogar",
      messages: "Mensajes",
      bookmarks: "Marcadores",
      subscription: "Suscripción",
      users: "Usuarios",
      calender: "Calendario",
    },
    buttons: {
      publish: "Publicar",
      subscribeNow: "Suscríbete ahora",
      showMore: "Mostrar más",
      all: "Todos",
      scheduled: "Programado",
      pending: "Pendiente",
    },
    tags: {
      job_seeker_post: " Publicación de Trabajadores (TBD)",
      job_seeker_profile: "Perfil de Trabajadores",
      emp_profile: "Perfil de Empleadores",
      emp_post: "Ofertas de Trabajo",
    },
    types: {
      jobseeker: "Buscador de empleo",
      employer: "Empleador",
    },
    actions: {
      reportUser: "Reportar usuario",
      reportPost: "Reportar publicación",
      repostPost: "Volver la publicación",
      bookmarkUser: "Marcar usuario",
      editPost: "Editar publicación",
      deletePost: "Eliminar publicación",
      pinToTop: " Fijar en la parte",
      UnPinToTop: "Desfijar ",
      blockUser: "Bloquear usuario",
      deleteChat: " Eliminar conversación",
      removeBookmark: "Quitar marcador",
      Showless: "Mostrar",
    },

    bookmarkOptions: {
      chats: " Mensajes",
      posts: "Publicaciones",
      profiles: "Perfiles",
    },
    subscriptionTexts: {
      cancelSubscription: "Por favor, cancele mi suscripción",
      value: "Valor de la suscripción",
      Duration: "Duración",
      Renews: "Se renueva el",
      Upgrade: "Actualiza a Suscriptor Premium.",
      UpgradeText:
        "Agregar destacados a tus publicaciones aumentará significativamente la interacción con otros usuarios de Boca En Boca. Esta es una suscripción mes a mes.",
      UpgradeButton: "Actualizar Mi Cuenta",
      Downgrade: "Degradar Mi Suscripción Premium",
      DowngradeText:
        "Al final de mi suscripción anual Premium, me gustaría degradar mi cuenta a una suscripción regular. La suscripción regular está completamente libre de anuncios. Agradecemos su apoyo.",
      DowngradeButton: "Degradar a Suscripción Regular",
      Premium: "Eres un Suscriptor Premium.",
      premiumText:
        "Todas tus publicaciones están destacadas. Esto aumentará significativamente la interacción con otros usuarios de Boca En Boca. Esta es una suscripción mes a mes.",
      piad: "Eres un Suscriptor Pago.",
      paidTexts:
        "Gracias por suscribirte a Boca En Boca. Estás ayudando a conectar empleadores con solicitantes de empleo sin anuncios. Agradecemos su apoyo.",
      annual: "Suscripción Anual",
      annualTexts:
        "Con una cuenta gratuita, encontrarás anuncios breves para ayudar a Boca En Boca a conectar a los empleadores con los solicitantes de empleo. Actualiza a nuestro plan de suscripción anual para disfrutar de una experiencia sin anuncios en toda la aplicación.",
      annualPrice: "$9,99/año",
      subscribeAccess: "Acceso de suscripción",
      paywithcard: "Pagar con tarjeta",
      thankYouTexts:
        "Gracias por su apoyo para ayudarnos a conectar a los empleadores con los solicitantes de empleo.",
      continue: "Continuar",
    },

    profileExtras: {
      posts: "Publicaciones",
      bookmarks: "Marcadores",
      responseTime: "Tiempo de respuesta",
      about: "Acerca de mí",
    },
    logoutmodal: {
      text: "Estás porcerrar sesión ¿estás seguro?",
      cancel: "Cancelar",
      confirm: "Confirmar",
    },
    loginmodal: {
      text: "Inicie sesión o cree una cuenta gratuita para continuar.",
      button: "Llévame a Iniciar sesión/Registrarse",
    },
    signUpPage: {
      signUp: "Regístrate",
      welcome: "Te damos la bienvenida a",
      firstName: "Nombre(s)",
      lastName: "Apellidos",
      email: "Correo electrónico",
      username: "Usuario",
      telephone: "Teléfono",
      password: "Contraseña",
      confirmPassword: "Confirmar contraseña",
      selectState: "Estado",
      selectCity: "Ciudad",
      jobSeeker: "Trabajador",
      employer: "Empleador",
      agreeTerms: "Acepto los Términos de uso y la Política de privacidad",
      signUpButton: "Registrarse",
    },
    loginPage: {
      welcome: "Te damos la bienvenida a",
      welcomeBack: "Bienvenido nuevamente",
      emailPhoneOrUsername: "Correo, Teléfono o Usuario",
      enterPassword: "Contraseña",
      rememberMe: "Recuérdame",
      forgotPassword: "Olvidé mi contraseña",
      logIn: "Iniciar Sesión",
      orSignInWith: "O inicia sesión con",
      noAccount: "¿No tienes una cuenta?",
      signUpForFree: "Regístrate gratis",
    },
    InterviewSchedule: {
      submit: "Enviar propuesta de entrevista",
      timeone: "Seleccione la opción de tiempo uno",
      timetwo: "Seleccione la opción de tiempo dos",
      interviewtype: "Seleccione el tipo de entrevista",
    },
  },
};

export default translations;
