import React from "react";
import ReactPlayer from "react-player";

const ImageGallery = ({ post, openModal }) => {
  // Ensure post is defined and has the expected structure
  if (!post) {
    return null; // Render nothing if post is undefined
  }

  const openModalHandler = (index) => openModal(post, index);

  // Combine images and videos into a single array
  const media = [
    ...(post.images || []).map((img) => ({ type: "image", file: img.file })),
    ...(post.videos || []).map((vid) => ({ type: "video", file: vid.file })),
  ];

  return (
    <div className="mt-3">
      {media.length > 0 && (
        <div
          className={`grid ${
            media.length === 1
              ? "grid-cols-1"
              : media.length === 2
              ? "grid-cols-2"
              : media.length === 3
              ? "grid-rows-2 grid-cols-2"
              : "grid-cols-2 grid-rows-2"
          } gap-1 mt-3 z-48 `}
        >
          {media.slice(0, 4).map((item, index) => (
            <div
              key={index}
              className={`relative ${
                media.length === 3 && index === 2 ? "col-span-2 row-span-2" : ""
              }`}
            >
              {item.type === "image" ? (
                <img
                  className={`cursor-pointer object-cover shadow-sm w-full h-full  ${
                    media.length === 1
                      ? "max-h-[500px]"
                      : "h-[150px] md:h-[300px] lg:h-[300px]"
                  }`}
                  src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${item.file}`}
                  alt={`Post Media ${index + 1}`}
                  onClick={() => openModalHandler(index)}
                />
              ) : (
                <ReactPlayer
                  url={`https://bocaendboca.s3.us-east-2.amazonaws.com/${item.file}`}
                  controls
                  width="100%"
                  height={media.length === 1 ? "500px" : "500px"}
                  className=" cursor-pointer object-cover "
                />
              )}

              {index === 3 && media.length > 4 && (
                <div
                  className="absolute inset-0 cursor-pointer bg-black/20 flex items-center justify-center text-white font-bold text-xl"
                  onClick={() => openModalHandler(index)}
                >
                  +{media.length - 4} more
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
